import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H1, P } from "../components/common/Typography";
import imageHero from "../assets/images/hero-philosophy.jpg";

const TITLE = "H φιλοσοφία μας";

function PhilosophyPage() {
  return (
    <Layout>
      <SEO title={TITLE} />
      <img
        css={`
          width: 100%;
          vertical-align: bottom;
          max-height: 476px;
          object-fit: cover;
        `}
        src={imageHero}
        alt="Philosophy"
      />
      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Στον χορό δεν υπάρχουν όρια. Όταν η ψυχή δεν βρίσκει λόγια, μιλάει το
          σώμα!
        </P>
        <P>
          Η ΘΟΔΩΡΗΣ ΠΑΝΑΣ ΣΧΟΛΗ ΧΟΡΟΥ ΑΘΗΝΩΝ ανοίγει τις πόρτες και την καρδιά
          της και σας προτρέπει να ξεκινήσετε χορό άμεσα!
        </P>
        <P>
          Ο συνδυασμός χορού και μουσικής είναι η χαρά της ζωής που μας δίνει τη
          δύναμη και την αυτοπεποίθηση να συνεχίζουμε.
        </P>
        <P>
          Όπως είχε πει άλλωστε και η Αμερικανίδα συγγραφέας και ποιήτρια Μάγια
          Αγγέλου "όλα στο σύμπαν έχουν έναν ρυθμό, όλα χορεύουν". Γιατί όχι και
          εμείς?
        </P>
        <P>
          Στην καρδιά της Αθήνας, στην Ακαδημία Πλάτωνος, δημιουργήσαμε μια
          σύγχρονη, διεθνών προδιαγραφών και πλήρως εξοπλισμένη σχολή,
          αναγνωρισμένη από το κράτος. Στόχος μας να γίνει κέντρο μάθησης, αλλά
          και χώρος συνάντησης για παιδιά, νέους, ενήλικες και επαγγελματίες.
        </P>
        <P>
          Για τη σωστή διαδικασία εκμάθησης φροντίσαμε ώστε η ομάδα μας να
          απαρτίζεται από επαγγελματικά καταξιωμένους καθηγητές. Με ένα
          κατάλληλο πρόγραμμα μαθημάτων που περιλαμβάνει πολλά είδη χορού,
          στοχεύουμε στην καλλιτεχνική διαπαιδαγώγηση των παιδιών κάθε ηλικίας,
          αλλά και στην εκγύμναση και στην καλλιτεχνική εκτόνωση των
          μεγαλύτερων.
        </P>
        <P>
          Με ιδιαίτερη χαρά θα περιμένουμε να σας ενημερώσουμε και να βοηθήσουμε
          στην επιλογή του κατάλληλου προγράμματος μαθημάτων για εσάς. Μαζί
          μπορούμε να συζητήσουμε και να κάμψουμε κάθε δισταγμό σας. Μετά, απλά
          αφεθείτε στην μαγεία του χορού!
        </P>
        <P>Θοδωρής Πανάς Χριστίνα Λεγάκι Ίων Κυπραίος</P>
      </Section.Main>
    </Layout>
  );
}

export default PhilosophyPage;
